import React from "react";

import ClientItem from "./ClientItem";
import "./ClientsList.css";

const ClientsList = (props) => {
  if (props.items.length === 0) {
    return (
      <div className="center">
        <h2>Aucun clients.</h2>
      </div>
    );
  }
  const clientsWithAiOff = props.items.filter(
    (client) =>
      client.ia_state === "off" &&
      !client.finished &&
      !client.fake_ongoing &&
      !client.actual_payer
  );
  const clientsOngoing = props.items.filter(
    (client) =>
      (client.ia_state === "on" ||
        client.fake_ongoing ||
        client.actual_payer) &&
      !client.finished
  );
  const clientsPaid = props.items.filter((client) => client.finished);

  const orderedClients = [
    ...clientsWithAiOff,
    ...clientsOngoing,
    ...clientsPaid,
  ];
  return (
    <ul className="clients-list">
      <div className="client-list__header">
        <span className="client-list__header__name">Nom</span>
        <span className="client-list__company">Entreprise</span>
        <span className="client-list__status">Status</span>
        <span className="client-list__start_date">Début</span>
        <span className="client-list__price">Montant</span>
        <span className="client-list__action">Action</span>
      </div>
      {orderedClients.map((client) => (
        <ClientItem
          key={client.id}
          id={client.id}
          name={client.name}
          logo={client.logo}
          bill={client.bill}
          conv={client.conversation}
          ai_state={client.ia_state}
          email={client.email}
          phone={client.phone}
          finished={!!client.finished}
          actual_payer={client.actual_payer}
          fake_ongoing={client.fake_ongoing}
          bill_price={client.bill_price}
          first_msg_date={client.first_msg_date}
          onDelete={props.onDeleteClient}
          onEdit={props.onEditClient}
        />
      ))}
    </ul>
  );
};

export default ClientsList;
