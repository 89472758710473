import "./AudioPlayer.css";
import React, { useRef, useState, useEffect } from "react";
import Howler from "react-howler";
import { ReactComponent as PlayerPause } from "../../../assets/icons/player_play_icon.svg";

import { ReactComponent as PlayerPlay } from "../../../assets/icons/player_pause_icon.svg";
const CustomAudioPlayer = ({ voice_msg_aws_url }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef(null);
  const intervalRef = useRef(null);
  const sliderRef = useRef(null);
  const isDragging = useRef(false);
  const wasPlayingBeforeDrag = useRef(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleLoad = () => {
    const audioDuration = playerRef.current?.howler?.duration();
    setDuration(Math.floor(audioDuration));
  };

  useEffect(() => {
    if (isPlaying) {
      intervalRef.current = setInterval(() => {
        const time = playerRef.current?.howler?.seek() || 0;
        setCurrentTime(time);
      }, 100);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isPlaying, duration]);

  const seekAudio = (event) => {
    if (!sliderRef.current || !duration) return;

    const { left, width } = sliderRef.current.getBoundingClientRect();
    const clickPosition = event.clientX - left;
    const clickRatio = clickPosition / width;
    const newTime = clickRatio * duration;

    playerRef.current?.howler?.seek(newTime);
    setCurrentTime(newTime);
  };

  const handleMouseDown = (event) => {
    if (isPlaying) {
      wasPlayingBeforeDrag.current = true;
      setIsPlaying(false);
    }
    isDragging.current = true;
    seekAudio(event);
  };

  const handleMouseMove = (event) => {
    if (isDragging.current) {
      seekAudio(event);
    }
  };

  const handleMouseUp = () => {
    if (wasPlayingBeforeDrag.current) {
      setIsPlaying(true);
      wasPlayingBeforeDrag.current = false;
    }
    isDragging.current = false;
  };

  // Fixed heights for the 15 bars to keep them static
  const bars = [12, 14, 10, 8, 16, 12, 14, 10, 8, 16, 12, 14, 10, 8, 16];

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="audio-player">
      <div className="play-button" onClick={togglePlayPause}>
        {isPlaying ? (
          <PlayerPause className="icon-button" />
        ) : (
          <PlayerPlay className="icon-button" />
        )}
      </div>
      <div
        className="waveform-slider"
        ref={sliderRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {bars.map((height, index) => {
          const barProgress = currentTime / duration;
          const barPosition = index / bars.length;

          // Determine color intensity based on proximity to the current playback position
          const isPlayed = barPosition < barProgress;
          const isNext =
            barPosition >= barProgress && barPosition <= barProgress + 0.1;

          return (
            <div
              key={index}
              className="waveform-bar"
              style={{
                height: `${height}px`,
                opacity: isPlayed ? 0.66 : isNext ? 0.58 : 0.5,
                backgroundColor: `rgba(0, 0, 0, ${
                  isPlayed ? 0.66 : isNext ? 0.58 : 0.5
                })`,
                transition: isNext ? "opacity 0.3s ease" : "none",
              }}
            ></div>
          );
        })}
      </div>
      <div className="audio-duration">
        {duration ? formatTime(duration) : "Loading..."}
      </div>
      <Howler
        src={voice_msg_aws_url}
        playing={isPlaying}
        ref={playerRef}
        onLoad={handleLoad}
        onEnd={() => {
          setIsPlaying(false);
          setCurrentTime(0);
          clearInterval(intervalRef.current);
        }}
      />
    </div>
  );
};

export default CustomAudioPlayer;
