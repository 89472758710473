import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-button-image-preview.svg";
import "./FileDropZone.css";

// Configure PDF.js to use the locally imported worker

function FileDropZone(props) {
  const [isDragging, setIsDragging] = useState(false);
  const [isFileValid, setIsFileValid] = useState(true);
  const [currentFile, setCurrentFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState(null);
  const filePickerRef = useRef();

  useEffect(() => {
    if (!currentFile) {
      return;
    }

    const fileType = currentFile.type;

    if (fileType === "application/pdf") {
      const fileReader = new FileReader();
      // fileReader.onload = async () => {
      //   // const pdfData = new Uint8Array(fileReader.result);
      //   // const pdf = await pdfjsLib.getDocument(pdfData).promise;
      //   // const page = await pdf.getPage(1);
      //   // const viewport = page.getViewport({ scale: 1 });
      //   // const canvas = document.createElement("canvas");
      //   // const context = canvas.getContext("2d");
      //   // canvas.height = viewport.height;
      //   // canvas.width = viewport.width;

      //   // await page.render({ canvasContext: context, viewport }).promise;
      //   // setFilePreviewUrl(canvas.toDataURL());
      // };
      fileReader.readAsArrayBuffer(currentFile);
    } else {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setFilePreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(currentFile);
    }
  }, [currentFile]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);

    const fileType = e.dataTransfer.items[0]?.type;
    if (fileType) {
      setIsFileValid(/(pdf|jpeg|jpg|png)$/.test(fileType));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);

    const fileType = e.dataTransfer.items[0]?.type;
    if (fileType) {
      setIsFileValid(/(pdf|jpeg|jpg|png)$/.test(fileType));
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setIsFileValid(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length) {
      const droppedFile = files[0];
      const isValidFile = /\.(pdf|jpeg|jpg|png)$/i.test(droppedFile.name);

      if (isValidFile) {
        setCurrentFile(droppedFile);
        props.onInput(props.id, droppedFile, true);
      } else {
        alert(
          "Invalid file type. Please upload a PDF, JPEG, JPG, or PNG file."
        );
      }
      setIsFileValid(isValidFile);
    }
  };

  const handleFilePicked = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      const pickedFile = event.target.files[0];
      const isValidFile = /\.(pdf|jpeg|jpg|png)$/i.test(pickedFile.name);

      if (isValidFile) {
        setCurrentFile(pickedFile);

        if (pickedFile.type === "application/pdf") {
          // Generate a URL for the PDF file
          const fileURL = URL.createObjectURL(pickedFile);
          setFilePreviewUrl(fileURL);
        } else {
          // Handle image file preview
          const fileReader = new FileReader();
          fileReader.onload = () => {
            setFilePreviewUrl(fileReader.result);
          };
          fileReader.readAsDataURL(pickedFile);
        }

        props.onInput(props.id, pickedFile, true);
      } else {
        alert(
          "Invalid file type. Please upload a PDF, JPEG, JPG, or PNG file."
        );
        props.onInput(props.id, undefined, false);
      }
    }
    event.target.value = "";
  };

  const pickButtonHandler = () => {
    filePickerRef.current.click();
  };

  const deleteFileHandler = () => {
    setCurrentFile(null);
    setFilePreviewUrl(null);
    props.onInput(props.id, undefined, false);
  };

  return (
    <div>
      {filePreviewUrl && (
        <div className="file-loaded-preview-container">
          {currentFile.type === "application/pdf" ? (
            <iframe
              src={filePreviewUrl}
              className="pdf-preview"
              frameBorder="0"
              title="PDF Preview"
            ></iframe>
          ) : (
            <img
              className="image-file-upload--preview"
              src={filePreviewUrl}
              alt="Preview"
            />
          )}
          <DeleteIcon
            className="button-delete-image-preview"
            onClick={deleteFileHandler}
          />
        </div>
      )}
      {!filePreviewUrl && (
        <div
          className={`drop-zone ${isDragging ? "dragging" : ""} ${
            !isFileValid ? "invalid-file" : ""
          }`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div>
            <input
              id={props.id}
              ref={filePickerRef}
              style={{ display: "none" }}
              type="file"
              accept=".jpg,.png,.jpeg,.pdf"
              onChange={handleFilePicked}
            />
            <div className="upload-file-container">
              <UploadIcon />
              <div>
                <span>Glissez et déposez ici ou&nbsp;</span>
                <span
                  className="pick-file-for-upload"
                  onClick={pickButtonHandler}
                >
                  sélectionnez un fichier
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FileDropZone;
