import ConvMessage from "./ConvMessage";
import "./MessageList.css";
import React, { useEffect, useRef } from "react";
import { ReactComponent as MoneyIcon } from "../../assets/icons/money-icon.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/assistance-icon.svg";
import { dateFormatDayOfWeek } from "../../shared/util/format_utils";

const MessageList = ({ billPrice, convState, conversation }) => {
  // console.log()
  const timelineRef = useRef(null);

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
    }
  }, []);

  const isNewDay = (currentTimestamp, previousTimestamp) => {
    const currentDate = new Date(currentTimestamp).toDateString();
    const previousDate = previousTimestamp
      ? new Date(previousTimestamp).toDateString()
      : null;
    return currentDate !== previousDate;
  };

  if (conversation == undefined) {
    return <p className="no-conversation-text">Aucune conversation</p>;
  }

  return (
    <div ref={timelineRef} className="message-list">
      {conversation.map((message, index) => {
        const showDate =
          index === 0 ||
          (isNewDay(message.timestamp, conversation[index - 1]?.timestamp) &&
            message.type !== "email_scheduled");
        return (
          <React.Fragment key={index}>
            {showDate && (
              <p className="message-date">
                {dateFormatDayOfWeek(message.timestamp)}
              </p>
            )}
            <ConvMessage message={message} />
          </React.Fragment>
        );
      })}
      {!convState.fake_ongoing && convState.finished && (
        <div className="footer-msg-msg-list">
          <MoneyIcon />{" "}
          <p>Le paiement de {billPrice} a bien été fait. Dossier clôturé</p>
        </div>
      )}
      {!convState.fake_ongoing && !convState.finished &&
        convState.aiState === "off" &&
        (!convState.actual_payer) && (
          <div className="footer-msg-msg-list">
            <SupportIcon />{" "}
            <p>
              Jengo n’a pas pu résoudre la situation. Le dossier requiert une
              intervention humaine.
            </p>
          </div>
        )}
      {(!convState.finished && convState.actual_payer) && (
        <div className="footer-msg-msg-list">
          <SupportIcon />{" "}
          <p>
          Changement de contact. Mail envoyé à {convState.actual_payer}
          </p>
        </div>
      )}
    </div>
  );
};

export default MessageList;
