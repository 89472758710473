import React, { useContext } from "react";

import "./ConvHeader.css";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow_left.svg";
import { ReactComponent as ExpectedIncomeIcon } from "../../assets/icons/price_excpected.svg";
import { ReactComponent as IncomeIcon } from "../../assets/icons/mes_encaissements.svg";
import { ReactComponent as FutureIncomeIcon } from "../../assets/icons/reste_encaisser.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/msg_icon.svg";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/vert_ellipsis.svg";
import { AuthContext } from "../../shared/context/auth-context";

const ConvHeader = ({ headerInfo }) => {
  const auth = useContext(AuthContext);
  // console.log(headerInfo.aiStateClassName);
  return (
    <div className="conv-header-container">
      <div className="conv-details-client-info">
        <ArrowLeft
          onClick={() => (window.location.href = `/${auth.userId}/clients`)}
          className="conv-details-client-arrow-left"
        />
        <p className="conv-details-client-name">Dossier de {headerInfo.name}</p>
      </div>

      <div className="conv-header-recap-container">
        <div className="conv-header-folder-state-main-container">
          <div className="conv-header-folder-state-left-container">
            <p>Statut du dossier</p>
            <p>Dossier crée</p>
            <p>Langue du dossier</p>
            {/* <p style={{ marginBottom: "0px" }}>Prochaine relance</p> */}
          </div>
          <div className="conv-header-folder-state-right-container">
            <p className={headerInfo.aiStateClassName}>
              {headerInfo.aiStateTxt}
            </p>
            <p>{headerInfo.first_msg_date}</p>
            <p>Français</p>
            {/* <p style={{ marginBottom: "0px" }}>_</p> */}
          </div>
        </div>
        <div className="conv-header-income-container">
          <ExpectedIncomeIcon className="conv-header-income-icon" />
          <h1>Montant attendu</h1>
          <p>{headerInfo.billPrice}</p>
        </div>
        <div className="conv-header-income-container">
          <IncomeIcon className="conv-header-income-icon" />
          <h1>Encaissements</h1>
          <p>0 €</p>
        </div>
        <div className="conv-header-income-container">
          <FutureIncomeIcon className="conv-header-income-icon" />
          <h1>A encaisser</h1>
          <p>{headerInfo.billPrice}</p>
        </div>
      </div>
      {/* <div className="conv-details-client-search-container">
        <p className="conv-details-client-search-title">Activité</p>
        <div className="conv-details-client-search">
          <div className="conv-header-search-container">
            <SearchIcon className="conv-details-client-search-icon" />
            <input
              className="conv-details-client-search-input"
              placeholder="Rechercher"
            ></input>
          </div>
          <MessageIcon className="conv-details-client-msg-icon" />
          <EllipsisIcon className="conv-details-client-ellipsis-icon" />
        </div>
      </div> */}
    </div>
  );
};

export default ConvHeader;
