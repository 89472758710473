export const formatBillPrice = (billPrice) => {
  if (!billPrice) {
    return "_";
  }

  let priceStr = billPrice.toString().trim();
  priceStr = priceStr.replace(/(euros|euro|eur|eu|e|€)/gi, "").trim();
  priceStr = priceStr.replace(".", ",");
  if (!priceStr.includes(",")) {
    priceStr = parseFloat(priceStr).toFixed(2).replace(".", ",");
  }
  return priceStr + " €";
};

export const formatBillPricePlainText = (billPrice) => {
  if (!billPrice) {
    return "_";
  }

  let priceStr = billPrice.toString().trim();
  priceStr = priceStr.replace(/(euros|euro|eur|eu|e|€)/gi, "").trim();

  const price = parseFloat(priceStr);
  priceStr = price.toString().replace(".", ",");

  if (priceStr.includes(",")) {
    priceStr = priceStr.replace(/,?0+$/, "");
  }

  return priceStr + (price > 1 ? " euros" : " euro");
};

export const dateFormat = (dateString) => {
  if (!dateString) {
    return "_";
  }

  try {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
      throw new Error("Invalid date");
    }

    // Options for French formatting (day and month only, no year, no hour)
    const options = {
      day: "numeric",
      month: "long",
    };

    // Format the date in French without year or hour
    return date.toLocaleDateString("fr-FR", options);
  } catch (error) {
    // Return "_" in case of any error
    return "_";
  }
};

export const formatDateToHour = (dateString) => {
  if (!dateString) {
    return "_";
  }

  try {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
      throw new Error("Invalid date");
    }

    // Extract hours and minutes
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = Math.floor(date.getMinutes());
    const roundedMinutes = minutes.toString().padStart(2, "0");

    // Format as "HHhMM"
    return `${hours}h${roundedMinutes}`;
  } catch (error) {
    // Return "_" in case of any error
    return "_";
  }
};

export const dateFormatDayOfWeek = (timestamp) => {
  const daysOfWeek = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const date = new Date(timestamp);
  const dayName = daysOfWeek[date.getDay()];
  const day = date.getDate();
  const monthName = months[date.getMonth()];

  return `${dayName} ${day} ${monthName}`;
};
