import React, { useState } from "react";
import "./EmailScheduledMessage.css";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

import { ReactComponent as MailIconScheduled } from "../../../assets/icons/mail-scheduled.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_logo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import { dateFormat } from "../../../shared/util/format_utils.js";

const EmailMessage = ({ data }) => {
  let { messageClass, subject, content, timestamp, voice_msg_aws_url } = data;

  const [showFullContent, setShowFullContent] = useState(false);
  const toggleShowContent = () => setShowFullContent(!showFullContent);

  const truncateContent = (content) => {
    const lines = content.split("\n");
    if (lines.length > 5) {
      return lines.slice(0, 5).join("\n") + "...";
    }
    return content;
  };
  let date_day = dateFormat(timestamp);
  return (
    <div className={messageClass}>
      <div className="mail-title-container">
        <MailIconScheduled className="mail-icon" />
        <p>Relance automatique programmé</p>
        <p className="title-date-scheduled-mail">&nbsp;&nbsp;·&nbsp;&nbsp;pour le {date_day}</p>
      </div>
      <div className="msg-content-box">
        <div>
          <p className="mail-subject-txt">Objet : {subject} </p>
          <ReactMarkdown
            className="mail-content-txt"
            remarkPlugins={[remarkGfm]}
          >
            {showFullContent ? content : truncateContent(content)}
          </ReactMarkdown>
        </div>
        {content.split("\n").length > 5 && (
          <div className="toggle-content-container" onClick={toggleShowContent}>
            {showFullContent ? <p>Voir moins</p> : <p>Voir plus</p>}
            {showFullContent ? (
              <ArrowUp className="arrow-toggle-show" />
            ) : (
              <ArrowDown className="arrow-toggle-show" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailMessage;
