import React from "react";

// import { Link } from "react-router-dom";

import { NavLink } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_logo.svg";
import { ReactComponent as BillIcon } from "../../assets/icons/bill_logo.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/bin_logo.svg";
import { formatBillPrice, dateFormat } from "../../shared/util/format_utils.js";
import "./ClientItem.css";

const ClientItem = ({
  id,
  name,
  logo,
  bill,
  conv,
  ai_state,
  email,
  phone,
  finished,
  fake_ongoing,
  actual_payer,
  bill_price,
  first_msg_date,
  onDelete,
  onEdit,
}) => {
  // const auth = useContext(AuthContext);
  let [ai_state_txt, ai_state_class_name] = ["En cours", "ai-state-ongoing"];

  if (!fake_ongoing && finished) {
    [ai_state_txt, ai_state_class_name] = ["Réglé", "ai-state-paid"];
  } else if (!fake_ongoing && !!actual_payer) {
    [ai_state_txt, ai_state_class_name] = ["Transféré", "ai-state-ongoing"];
  } else if (!fake_ongoing && ai_state === "off") {
    [ai_state_txt, ai_state_class_name] = [
      "Assistance",
      "ai-state-human-intervention",
    ];
  }
  first_msg_date = dateFormat(first_msg_date);
  bill_price = formatBillPrice(bill_price);

  const handleBillClick = () => {
    const newWindow = window.open();
    newWindow.document.write(
      `<img src="${bill}" style="width:100vw;height:100vh;object-fit:contain;">`
    );
  };

  return (
    <>
      <li className="client-item">
        <NavLink className="client-item__link" to={`/${id}/client_details`}>
          <div className="client-item__info-container">
            <p className="client-item__name">{name}</p>
            <p className="client-item__email">{email}</p>
          </div>
        </NavLink>

        <p className="client-item__company">_</p>
        <p className={`client-item__status ${ai_state_class_name}`}>
          {ai_state_txt}
        </p>
        <p className="client-item__start-date">{first_msg_date}</p>
        <p className="client-item__price">{bill_price}</p>
        <div className="client-item__action-container">
          <EditIcon
            className="client-item__action-container__edit"
            onClick={() => onEdit(id)}
          />
          <BillIcon
            className="client-item__action-container__bill"
            onClick={handleBillClick}
          />
          <DeleteIcon
            className="client-item__action-container__bin"
            onClick={() => onDelete(id, name)}
          />
        </div>
      </li>
    </>
  );
};

export default ClientItem;
