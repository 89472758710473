import React, { useState } from "react";
import "./VoiceMessage.css";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

import { ReactComponent as SpeakerIcon } from "../../../assets/icons/speaker-icon3.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_logo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import CustomAudioPlayer from "./AudioPlayer";
import { formatDateToHour } from "../../../shared/util/format_utils";

const VoiceMessage = ({ data }) => {
  let { messageClass, subject, content, timestamp, voice_msg_aws_url } = data;

  const [showFullContent, setShowFullContent] = useState(false);
  const toggleShowContent = () => setShowFullContent(!showFullContent);

  const truncateContent = (content) => {
    const lines = content.split("\n");
    if (lines.length > 5) {
      return lines.slice(0, 5).join("\n") + "...";
    }
    return content;
  };

  let date_hour = formatDateToHour(timestamp);
  return (
    <div className={messageClass}>
      <div className="mail-title-container">
        <SpeakerIcon
          style={{width: "20px" }}
        />
        
        <p>Par téléphone</p>
        <p className="msg-title-hour-txt">&nbsp;· {date_hour}</p>
      </div>

      {/* <audio className="audio-voice-msg" controls src={voice_msg_aws_url} /> */}
      <div className="msg-content-box-voice-msg-container">
      <div className="msg-content-box-voice-msg">
        <CustomAudioPlayer voice_msg_aws_url={voice_msg_aws_url} />
      </div>
      </div>
      <div className="msg-content-box">
        <div>
          <p className="mail-subject-txt">Transcript :</p>
          <ReactMarkdown
            className="mail-content-txt"
            remarkPlugins={[remarkGfm]}
          >
            {showFullContent ? content : truncateContent(content)}
          </ReactMarkdown>
        </div>
        {content.split("\n").length > 5 && (
          <div className="toggle-content-container" onClick={toggleShowContent}>
            {showFullContent ? <p>Voir moins</p> : <p>Voir plus</p>}
            {showFullContent ? (
              <ArrowUp className="arrow-toggle-show" />
            ) : (
              <ArrowDown className="arrow-toggle-show" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceMessage;
