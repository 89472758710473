import React, { Suspense } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Clients from "./clients/pages/Clients";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import MainHeader from "./shared/components/Navigation/MainHeader";

import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import ConvDetails from "./conversation/pages/ConvDetails";

const Bills = React.lazy(() => import("./bills/pages/Bills"));
const Auth = React.lazy(() => import("./user/pages/Auth"));
const Dashboard = React.lazy(() => import("./dashboard/pages/Dashboard"));

const App = () => {
  const { token, login, logout, userId, userName } = useAuth();

  let routes;

  if (token) {
    routes = (
      <Routes>
        <Route path="/:userId/bills" element={<Bills />} />
        <Route path="/:userId/clients" element={<Clients />} />
        <Route path="/:userId/dashboard" element={<Dashboard />} />
        <Route path="/:clientId/client_details" element={<ConvDetails />} />
        <Route
          path="*"
          element={<Navigate to={`/${userId}/clients`} replace />}
        />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route
          path="*"
          element={<Navigate to="/auth" replace />}
        />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        userName: userName,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        {!!token && <MainNavigation />}
        {!!token && <MainHeader />}
        <main className="main-content">
          <Suspense
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
