import React from 'react';
import './MainHeader.css';
import { ReactComponent as SearchIcon } from "../../../assets/icons/search_icon.svg"
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings_icon.svg"
import { ReactComponent as NotificationIcon } from "../../../assets/icons/notification_icon.svg"
import { ReactComponent as UserIcon } from "../../../assets/icons/user_icon.svg"

const MainHeader = () => {
  return (
    <div className="main-header">
      <div className="main-header__search">
        <SearchIcon className="main-header-search"/>
        <input className="main-header-search-input" type="text" placeholder="Rechercher"/>
      </div>

      <div className="main-header__icons">
        <SettingsIcon className="main-header-settings"/>
        <NotificationIcon className="main-header-notif"/>
        <UserIcon className="main-header-user-icon"/>
      </div>
    </div>
  );
};

export default MainHeader;
