import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./ConvMessage.css";
import EmailMessage from "./message-components/EmailMessage";
import EmailScheduledMessage from "./message-components/EmailScheduledMessage";
import VoiceMessage from "./message-components/VoiceMessage";

const ConvMessage = ({ message }) => {
  const { type, sender, subject, content, timestamp, voice_msg_aws_url } =
    message;

  const isSentByUs =
    sender === "Us" || type === "email_scheduled" || type === "voice_msg";
  const messageClass = `${isSentByUs ? "sent" : "received"}`;

  const formatContentForReactMarkdown = (text) => {
    return text.replace(/\n/g, " &nbsp;\n\n");
  };

  const formatSubject = (text) => {
    if (!text) {
      return text
    }
    if (text.toLowerCase().startsWith("objet :")) {
      text = text.slice(7);
  }
  
  return text.trim();
  }

  let data = {
    messageClass,
    subject : formatSubject(subject),
    content: formatContentForReactMarkdown(content),
    timestamp,
    voice_msg_aws_url,
  };

  return (
    <div className="message-container">
      {type === "email" && <EmailMessage data={data} />}
      {type === "email_scheduled" && <EmailScheduledMessage data={data} />}
      {type === "voice_msg" && <VoiceMessage data={data} />}
    </div>
  );
};

export default ConvMessage;
