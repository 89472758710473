import React, { useEffect, useState, useContext } from "react";

// import { useNavigate } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Card from "../../shared/components/UIElements/Card";

import {
  VALIDATOR_REQUIRE,
  // VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import "./Client.css";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const UpdateClient = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedClient, setLoadedClient] = useState();
  // const navigate = useNavigate();

  const [formState, inputHandler, setFormData] = useForm(
    {
      client_name: {
        value: "",
        isValid: false,
      },
      // email: {
      //   value: "",
      //   isValid: false,
      // },
      phone: {
        value: "",
        isValid: true,
      },
      // ia_state: {
      //   value: "",
      //   isValid: false,
      // },
      // logo: {
      //   value: "",
      //   isValid: false,
      // },
    },
    false
  );

  useEffect(() => {
    const fetchClient = async () => {
      if (props.clientId === null) {
        return;
      }
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/clients/${props.clientId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setLoadedClient(responseData.clientRecord);
        setFormData(
          {
            client_name: {
              value: responseData.clientRecord.name,
              isValid: true,
            },
            // email: {
            //   value: responseData.clientRecord.email,
            //   isValid: true,
            // },
            phone: {
              value: responseData.clientRecord.phone,
              isValid: true,
            },
            // ia_state: {
            //   value: responseData.clientRecord.ia_state,
            //   isValid: true,
            // },
            // logo: {
            //   value: responseData.clientRecord.logo,
            //   isValid: false,
            // },
          },
          true
        );
      } catch (err) {}
    };
    fetchClient();
  }, [sendRequest, setFormData, props.clientId, auth.token]);

  const clientUpdateSubmitHandler = async (event) => {
    event.preventDefault();
    const updatedClient = {
      name: formState.inputs.client_name.value,
      phone: formState.inputs.phone.value,
    };
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/clients/${props.clientId}`,
        "PATCH",
        JSON.stringify(updatedClient),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      props.onDone(props.clientId, updatedClient);
    } catch (err) {}
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedClient && !error) {
    return (
      <div className="center">
        <Card>
          <h2>Client introuvable !</h2>
        </Card>
      </div>
    );
  }
  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && loadedClient && (
        <form className="form-edit-main-form" onSubmit={clientUpdateSubmitHandler}>
          <Input
            mainDivClass="form-edit-main-div"
            classNameTitle="form-edit-name-title"
            classNameInput="form-edit-name-input"
            classNameError="form-edit-error-input"

            id="client_name"
            element="input"
            type="text"
            label="Nom"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Veuillez entrer un nom valide."
            onInput={inputHandler}
            initialValue={loadedClient.name}
            initialValid={true}
          />

          <Input
            mainDivClass="form-edit-main-div"
            classNameTitle="form-edit-name-title"
            classNameInput="form-edit-name-input"
            classNameError="form-edit-error-input"
            id="phone"
            element="input"
            type="text"
            label="Numéro de téléphone"
            //TODO : not required
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Veuillez entrer un numéro valide."
            onInput={inputHandler}
            initialValue={loadedClient.phone}
            initialValid={true}
          />

          <button
            className="modal__footer__edit-button"
            type="submit"
            disabled={!formState.isValid}
          >
            Modifier
          </button>
        </form>
      )}
    </>
  );
};

export default UpdateClient;
