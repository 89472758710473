import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar_icon.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow_down_logo.svg";
import "./Calendar.css"

const Calendar = () => {
  return (
    <div className="header__calendar">
      <CalendarIcon className="header__calendar__icon" />
      <span className="header__calendar__text">Cette semaine</span>
      <ArrowDownIcon className="header__calendar__arrow-down-icon" />
    </div>
  );
};
export default Calendar;
