import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import DropFileZone from "../../shared/components/FilesManagement/FileDropZone";

import {
  VALIDATOR_REQUIRE,
  // VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import "./NewClient.css";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const NewClient = (props) => {
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      client_name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      phone: {
        value: "",
        isValid: true,
      },
      image: {
        value: null,
        isValid: false,
      },
    },
    false
  );

  const clientSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", formState.inputs.client_name.value);
      formData.append("ia_state", "on");
      formData.append("logo", "aucun logo");
      formData.append("email", formState.inputs.email.value);
      formData.append("phone", formState.inputs.phone.value);
      formData.append("bill", formState.inputs.image.value);

      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/clients",
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      props.onClientCreated();
    } catch (err) {}
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <form className="new-client-form" onSubmit={clientSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}

        <Input
          mainDivClass="form-new-client-main-div"
          classNameTitle="form-new-client-name-title"
          classNameInput="form-new-client-name-input"
          classNameError="form-new-client-error-input"
          placeholder="Votre nom"
          id="client_name"
          element="input"
          type="text"
          label="Nom"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Veuillez entrer un nom valide."
          onInput={inputHandler}
        />
        <Input
          mainDivClass="form-new-client-main-div"
          classNameTitle="form-new-client-name-title"
          classNameInput="form-new-client-name-input"
          classNameError="form-new-client-error-input"
          placeholder="Votre email"

          id="email"
          element="input"
          type="text"
          label="Email"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Veuillez entrer un email valide."
          onInput={inputHandler}
        />
        <Input
          mainDivClass="form-new-client-main-div"
          classNameTitle="form-new-client-name-title"
          classNameInput="form-new-client-name-input"
          classNameError="form-new-client-error-input"
          placeholder="Votre numéro"

          id="phone"
          element="input"
          type="text"
          label="Numéro de téléphone"
          //TODO : phone not required
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Veuillez entrer un numéro valide."
          onInput={inputHandler}
        />
        {/* <ImageUpload className="image-upload-main"
          id="image"
          onInput={inputHandler}
          // errorText="Veuillez ajouter une facture."
        /> */}
        <p className="form-new-client-name-title">Facture</p>
        <DropFileZone id="image" onInput={inputHandler}/>

        <button className="create-new-client-button" type="submit" disabled={!formState.isValid}>
          Ajouter
        </button>
      </form>
    </>
  );
};

export default NewClient;
