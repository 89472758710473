import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import MessageList from "../components/MessageList";
import ConvHeader from "../components/ConvHeader";
import "./ConvDetails.css";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import {
  formatBillPrice,
  dateFormat,
  formatBillPricePlainText,
} from "../../shared/util/format_utils.js";

const POLLING_INTERVAL = 3000;

const ConvDetails = () => {
  const { sendRequest } = useHttpClient();

  const auth = useContext(AuthContext);
  const clientId = useParams().clientId;
  const [loadedClient, setLoadedClient] = useState(null);
  const [showConv, setShowConv] = useState(false);

  const fetchClient = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/clients/${clientId}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      const clientHaveChanged =
        JSON.stringify(responseData.clientRecord) !==
        JSON.stringify(loadedClient);

      if (clientHaveChanged) {
        try {
          responseData.clientRecord.conversation = JSON.parse(
            responseData.clientRecord.conversation
          );
          setShowConv(true);

          if (
            responseData.clientRecord.conversation.conv_history === undefined ||
            responseData.clientRecord.conversation.conv_history.length <= 0
          ) {
            throw Error("no conv history");
          }
        } catch (err) {
          responseData.clientRecord.conversation = "Cant parse";
          setShowConv(false);
        }
        setLoadedClient(responseData.clientRecord);
      }
    } catch (err) {}
  }, [sendRequest, clientId, auth.token]);

  useEffect(() => {
    fetchClient();

    const intervalId = setInterval(() => {
      fetchClient();
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchClient]);

  if (!loadedClient) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const name = loadedClient.name || "Nom inconnu";
  const phone = loadedClient.phone || "Téléphone inconnu";
  const email = loadedClient.email || "Email inconnu";
  const billPrice = formatBillPrice(loadedClient.bill_price);
  const billPricePlainText = formatBillPricePlainText(loadedClient.bill_price);
  const finished = loadedClient.finished || false;
  const aiState = loadedClient.ia_state || "off";
  const actual_payer = loadedClient.actual_payer || false;
  const fake_ongoing = loadedClient.fake_ongoing || false
  const first_msg_date = dateFormat(loadedClient.first_msg_date);

  let [aiStateTxt, aiStateClassName] = [
    "En cours",
    "ai-state-ongoing-conv-header",
  ];
  if (finished) {
    [aiStateTxt, aiStateClassName] = ["Réglé", "ai-state-paid-conv-header"];
  } else if (!fake_ongoing && !!actual_payer) {
    [aiStateTxt, aiStateClassName] = [
      "Transféré",
      "ai-state-ongoing-conv-header",
    ];
  } else if (!fake_ongoing && aiState === "off") {
    [aiStateTxt, aiStateClassName] = [
      "Assistance",
      "ai-state-human-intervention-conv-header",
    ];
  }

  let headerInfo = {
    name,
    phone,
    email,
    billPrice,
    finished,
    aiStateTxt,
    aiStateClassName,
    first_msg_date,
  };

  let convState = {
    finished,
    aiState,
    actual_payer,
    fake_ongoing,
  };

  return (
    <div className="conv-details-container adapt-to-side-nav">
      <ConvHeader headerInfo={headerInfo} />
      {showConv ? (
        <MessageList
          billPrice={billPricePlainText}
          convState={convState}
          conversation={loadedClient.conversation.conv_history}
        />
      ) : (
        <p className="no-conversation-text">Aucune conversation</p>
      )}
    </div>
  );
};

export default ConvDetails;
