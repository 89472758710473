import React from "react";
import "./ClientListHeader.css";
// import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
// import { ReactComponent as SortIcon } from "../../assets/icons/sort_logo.svg";
// import Calendar from "../../shared/components/Calendar/Calendar";

const ClientListHeader = ({ onClick }) => {
  return (
    <div className="client-list-header">
      <div className="client-list-header__top-row">
        <div className="client-list-header__text">
          <h1 className="client-list-header__title">Liste client</h1>
          <p className="client-list-header__subtitle">
            Découvrez tous les clients
          </p>
        </div>
        <button className="client-list-header__button" onClick={onClick}>
          <span className="plus-sign-add-client">+</span> Ajouter un client
        </button>
      </div>

      {/* <div className="client-list-header__controls">
        <div className="client-list-header__search">
          <SearchIcon className="client-list-header-search" />
          <input
            type="text"
            className="client-list-header-search-input"
            placeholder="Rechercher"
          />
        </div>
        <div className="client-list-header__sort">
          <SortIcon className="client-list-header__sort__icon" />
          <span className="client-list-header__sort__text">Filtre</span>
        </div>
      
        <Calendar />
      </div> */}
    </div>
  );
};

export default ClientListHeader;
