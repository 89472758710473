import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import "./NavLinks.css";
import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard_nav_logo.svg";
import { ReactComponent as ClientsIcon } from "../../../assets/icons/clients_nav_logo.svg";
import { ReactComponent as AgentAiIcon } from "../../../assets/icons/agent_ia_nav_logo.svg";
import { ReactComponent as PaiementsIcon } from "../../../assets/icons/paiements_nav_logo.svg";
import { ReactComponent as SupportIcon } from "../../../assets/icons/support_nav_logo.svg";
import { ReactComponent as DeconnectionIcon } from "../../../assets/icons/deconnection_nav_logo.svg";

const NavLinks = (props) => {
  const auth = useContext(AuthContext);

  return (
    <ul className="nav-links">
      {auth.isLoggedIn && (
        <>
          <li>
            <NavLink to={`/${auth.userId}/dashboard`}>
              <DashboardIcon className="nav-links-icon"/>
              <span className="nav-links-txt">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${auth.userId}/clients`}>
              <ClientsIcon className="nav-links-icon"/>
              <span className="nav-links-txt">Clients</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${auth.userId}/ai_agent`}>
              <AgentAiIcon className="nav-links-icon"/>
              <span className="nav-links-txt">Agent IA</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${auth.userId}/bills`}>
              <PaiementsIcon className="nav-links-icon"/>
              <span className="nav-links-txt">Paiements</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${auth.userId}/support`}>
              <SupportIcon className="nav-links-icon"/>
              <span className="nav-links-txt">Support</span>
            </NavLink>
          </li>
          <li>
            <div className="deconnection-container" onClick={auth.logout}>
              <DeconnectionIcon />
              <span> Déconnexion</span>
            </div>
          </li>
        </>
      )}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth">Connexion</NavLink>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
