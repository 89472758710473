import React, { useContext } from "react";
import { Link } from "react-router-dom";

import SideNav from "./SideNav";
import NavLinks from "./NavLinks";
import "./MainNavigation.css";
import { AuthContext } from "../../../shared/context/auth-context";
import { ReactComponent as JengoLogo } from "../../../assets/icons/jengo_logo.svg";

const MainNavigation = (props) => {
  const auth = useContext(AuthContext);

  return (
    <React.Fragment>
      <SideNav>
        <div className="side-navigation-title__container">
          <JengoLogo className="side-navigation__logo"/>
          <h1 className="side-navigation__title">
            <Link to={`/${auth.userId}/clients`}>Jengo</Link>
          </h1>
        </div>
        <nav className="side-navigation__nav-links">
          <NavLinks />
        </nav>
      </SideNav>
    </React.Fragment>
  );
};

export default MainNavigation;
