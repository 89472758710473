import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";

import ClientsList from "../components/ClientsList";
import Modal from "../../shared/components/UIElements/Modal";
import NewClient from "./NewClient";
import UpdateClient from "./UpdateClient";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ClientListHeader from "../components/ClientListHeader";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/sort_logo.svg";
import Calendar from "../../shared/components/Calendar/Calendar";

import "./Client.css";
import "./NewClient.css";
import "./UpdateClient.css";
const POLLING_INTERVAL = 3000;

const Clients = () => {
  const [loadedClientsRecords, setLoadedClientsRecords] = useState();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const userId = useParams().userId;

  const fetchClients = useCallback(
    async (display_loading = true) => {
      try {
        if (!loadedClientsRecords && display_loading) {
          setIsLoading(true);
        }

        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + `/clients/user/${userId}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setIsLoading(false);

        const clientsHaveChanged =
          JSON.stringify(responseData.clients) !==
          JSON.stringify(loadedClientsRecords);

        if (clientsHaveChanged) {
          setLoadedClientsRecords(responseData.clients);
        }

        // console.log(responseData.clients);
      } catch (err) {
        setLoadedClientsRecords([]);
      }
    },
    [auth.token, sendRequest, userId]
  );

  useEffect(() => {
    fetchClients();

    const intervalId = setInterval(() => {
      fetchClients(false);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchClients]);

  const [showNewClient, setShowNewClient] = useState(false);
  const [editClientId, setEditClientId] = useState(null);
  const closeNewClientHandler = () => setShowNewClient(false);
  const openNewClientHandler = () => setShowNewClient(true);

  const editClientHandler = (clientId) => {
    setEditClientId(clientId);
  };

  const closeEditClientHandler = () => setEditClientId(null);

  const clientUpdatedHandler = (updatedClientId, updatedClient) => {
    setEditClientId(null);
    fetchClients();
  };

  const clientCreationHandler = () => {
    setShowNewClient(false);
    fetchClients();
  };

  const clientDeletedHandler = (deletedClientId, deletedClientName) => {
    setShowDeleteConfirmModal(true);
    setClientToDelete([deletedClientId, deletedClientName]);
  };

  const cancelDeleteHandler = () => {
    setShowDeleteConfirmModal(false);
    setClientToDelete(null);
  };

  const confirmDeleteHandler = async () => {
    setShowDeleteConfirmModal(false);
    let client_id = clientToDelete[0];
    if (!client_id) {
      throw new Error("Can't delete client id null");
    }
    try {
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/clients/${client_id}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}
    console.log(`supprimer ${client_id}`);

    fetchClients();
  };

  return (
    <div className="main-client-div adapt-to-side-nav">
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <Modal
        show={showNewClient}
        onCancel={closeNewClientHandler}
        className="modal-new-client"
        header={"Ajouter un client"}
      >
        <NewClient onClientCreated={clientCreationHandler} />
      </Modal>
      <Modal
        show={editClientId != null}
        onCancel={closeEditClientHandler}
        header={"Modifier un client"}
        className="modal-update-client"
      >
        <UpdateClient clientId={editClientId} onDone={clientUpdatedHandler} />
      </Modal>
      <Modal
        show={showDeleteConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Supprimer un client"
        footerClass="place-item__modal-actions"
        className="modal-confirm-delete-client"
        onDeleteClick={confirmDeleteHandler}
        isConfirmDeleteModal={true}
      >
        {clientToDelete != null && (
          <p className="confirmation-text-delete-popup">
            Êtes-vous sur de vouloir supprimer le profil de {clientToDelete[1]}{" "}
            ?
          </p>
        )}
      </Modal>
      <ClientListHeader onClick={openNewClientHandler} />
      <div className="client-list-header-and-list-container">
        <div className="client-list-header__controls">
          <div className="client-list-header__search">
            <SearchIcon className="client-list-header-search" />
            <input
              type="text"
              className="client-list-header-search-input"
              placeholder="Rechercher"
            />
          </div>
          <div className="client-list-header__sort">
            <SortIcon className="client-list-header__sort__icon" />
            <span className="client-list-header__sort__text">Filtre</span>
          </div>

          <Calendar />
        </div>
        {!isLoading && loadedClientsRecords && (
          <ClientsList
            items={loadedClientsRecords}
            onDeleteClient={clientDeletedHandler}
            onEditClient={editClientHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Clients;
