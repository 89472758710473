import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_logo.svg";

import Backdrop from "./Backdrop";
import "./Modal.css";

const ModalOverlay = (props) => {
  const content = (
    <div className={`modal-general ${props.className}`} style={props.style}>
      <div className="modal__header">
        <p>{props.header}</p>
        <CloseIcon onClick={props.onCancel} className="modal__header__cross" />
      </div>

      {props.children}
      {
        !!props.isConfirmDeleteModal && (
          <div className="modal__footer_delete_container">
            <button onClick={props.onCancel} className="modal__footer__cancel">
              Annuler
            </button>
            <button
              onClick={props.onDeleteClick}
              className="modal__footer__delete"
            >
              Supprimer
            </button>
          </div>
        )
        // : (
        //   <div className="modal__footer">
        //     <button className="modal__footer__main-button">
        //       {props.submit_button_name}
        //     </button>
        //   </div>
        // )
      }
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;
