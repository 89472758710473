import React, { useState } from "react";
import "./EmailMessage.css";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";

import { ReactComponent as MailIcon } from "../../../assets/icons/mail-icon.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_logo.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import {formatDateToHour} from "../../../shared/util/format_utils";

const EmailMessage = ({ data }) => {
  let { messageClass, subject, content, timestamp, voice_msg_aws_url } = data;

  const [showFullContent, setShowFullContent] = useState(false);
  const toggleShowContent = () => setShowFullContent(!showFullContent);

  const truncateContent = (content) => {
    const lines = content.split("\n");
    if (lines.length > 5) {
      return lines.slice(0, 5).join("\n") + "...";
    }
    return content;
  };
  let date_hour = formatDateToHour(timestamp);

  return (
    <div className={messageClass}>
      <div className="mail-title-container">
        <MailIcon className="mail-icon" />
        <p>Envoyé par mail</p>
        <p className="msg-title-hour-txt">&nbsp;· {date_hour}</p>
      </div>
      <div className="msg-content-box">
      <div>
        <p className="mail-subject-txt">Objet : {subject} </p>
        <ReactMarkdown className="mail-content-txt" remarkPlugins={[remarkGfm]}>
          {showFullContent ? content : truncateContent(content)}
        </ReactMarkdown>
      </div>
      {content.split("\n").length > 5 && (
        <div className="toggle-content-container" onClick={toggleShowContent}>
          {showFullContent ? <p>Voir moins</p> : <p>Voir plus</p>}
          {showFullContent ? (
            <ArrowUp className="arrow-toggle-show" />
          ) : (
            <ArrowDown className="arrow-toggle-show" />
          )}
        </div>
      )}
      </div>
    </div>
  );
};

export default EmailMessage;
